export default class FPegawai {
  constructor(
    id,
    kode1,
    description,
    fdivisionBean,
    statusActive,

    golongan,
    jabatan,
    address,
    city,
    email,
    phone,

    avatarImage,

    created,
    modified,
    modifiedBy
  ) {

    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;

    this.golongan = golongan;
    this.jabatan = jabatan;
    this.address = address;
    this.city = city;
    this.email = email;
    this.phone = phone;

    this.avatarImage = avatarImage;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
